<template>
  <transition name="fade">
    <div
      v-if="visible"
      class="modal-overlay"
      @click="closeModal"
    >
      <transition name="slide-right">
        <div
          v-if="visible"
          class="modal-wrapper"
          @click.stop
        >
          <v-card class="slide-in-modal">
            <v-card-title>
              <span class="headline">Filters</span>
              <v-spacer />
              <v-btn @click="closeModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <slot />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                class="marRightCust"
                @click="applyFilters"
              >
                Apply
              </v-btn>
              <v-btn @click="closeModal">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      selectedFilter: [],
      //filters: ['Filter 1', 'Filter 2', 'Filter 3'],
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
    applyFilters() {
      this.$emit("filterData");
      this.closeModal();
    },
  },
};
</script>

<style lang="sass" scoped>
.modal-overlay 
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.5)
  z-index: 1000 
  display: flex
  justify-content: flex-end 
  align-items: center


.modal-wrapper 
  width: 490px


.slide-in-modal 
  width: 100% !important
  height: auto !important
  padding: 0 !important


.fade-enter-active
  transition: opacity 0.3s ease

.fade-leave-active 
  transition: opacity 0.3s ease

.fade-enter
  opacity: 0

.fade-leave-to 
  opacity: 0


.slide-right-enter-active
  transition: transform 0.3s ease

.slide-right-leave-active 
  transition: transform 0.3s ease


.slide-right-enter
  transform: translateX(100%)


.slide-right-leave-to 
  transform: translateX(100%)

.v-application
  .v-card__title
    border-bottom: 1px solid #D9D9D9
    margin-bottom: 1.5rem
    .headline
      font-size:1.375rem !important
      font-family: 'Poppins Medium' !important
    .v-btn
      background: transparent !important
      box-shadow: 0 0 0 0 !important
      padding:0 !important
      min-width: unset !important
      right: -0.6rem !important
      .v-btn__content
        i
          color: #000 !important
          font-size: 1.6rem
body .headerBarWrap .marRightCust.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab), 
body .headerBarWrap .marRightCust.v-btn.v-size--large:not(.v-btn--icon):not(.v-btn--fab)
  margin-right: 0.9rem !important
 
body .headerBarWrap .v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab), 
body .headerBarWrap .v-btn.v-size--large:not(.v-btn--icon):not(.v-btn--fab)
  margin-bottom: 0.9rem !important
</style>
