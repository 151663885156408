<template>
  <div class="DateDropDown">
    <v-card>
      <v-select
        v-model="selectedDate"
        filled
        :dense="dense"
        :items="dateOptions"
        item-text="name"
        item-value="id"
        :clearable="!defaultSelected"
        :label="$t('select_date')"
        @change="onDateChange"
      />
    </v-card>
    <v-card v-if="selectedDate == 'custom'">
      <date-time
        v-if="dateType == 'datetime'"
        v-model="dateFrom"
        :label="$t('from')"
        :min="validationOneMonth()"
        :max="dateTo ? dateTo : currentDate()"
        @change="isCheckRange"
      />
      <v-menu
        v-if="dateType == 'date'"
        v-model="isDateFrom"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- v-model="dateFrom" -->
          <v-text-field
            :value="formatedDateFrom"
            :label="$t('from')"
            readonly
            :dense="dense"
            v-bind="attrs"
            clearable
            filled
            @click:clear="() => (dateFrom = null)"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dateFrom"
          :min="validationOneMonth()"
          :max="dateTo ? dateTo : currentDate()"
          no-title
          @change="isCheckRange"
        />
      </v-menu>
    </v-card>
    <v-card v-if="selectedDate == 'custom'">
      <date-time
        v-if="dateType == 'datetime'"
        v-model="dateTo"
        :label="$t('to')"
        :min="dateFrom ? dateFrom : validationOneMonth()"
        :max="currentDate()"
        @change="isCheckRange"
      />
      <v-menu
        v-if="dateType == 'date'"
        v-model="isDateTo"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- v-model="dateTo" -->
          <v-text-field
            :value="formatedDateTo"
            :label="$t('to')"
            :dense="dense"
            readonly
            v-bind="attrs"
            clearable
            filled
            @click:clear="() => (dateTo = null)"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dateTo"
          :min="dateFrom ? dateFrom : validationOneMonth()"
          :max="currentDate()"
          no-title
          @change="isCheckRange"
        />
      </v-menu>
    </v-card>
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {
    DateTime: () => import("@/components/base/DateTime.vue"),
  },
  props: {
    dateRange: {
      type: [Array, null],
      default: () => {
        return [];
      },
    },
    cols: {
      type: String,
      default: () => {
        return "3";
      },
    },
    dense: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    dateType: {
      type: String,
      default() {
        return "date";
      },
    },
    custom: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    defaultSelected: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    validateDateLimit: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data() {
    return {
      selectedDate: "",
      // selectedDate:
      //   this.$route.query.dateType ?? this.defaultSelected ? "today" : "",
      // date: this.$route.query.dateRange ?? [this.currentDate()],
      dateFrom: this.$route.query.dateRange
        ? this.$route.query.dateRange[0]
        : this.dateRange
        ? this.dateRange[0]
        : "",
      dateTo: this.$route.query.dateRange
        ? this.$route.query.dateRange[1]
        : this.dateRange
        ? this.dateRange[1]
        : "",
      isDateFrom: false,
      isDateTo: false,

      dateOptions: [
        {
          id: "today",
          name: "Today",
        },
        {
          id: "yesterday",
          name: "Yesterday",
        },
        {
          id: "last_3_days",
          name: "Last 3 days",
        },
        {
          id: "last_7_days",
          name: "Last 7 days",
        },
        {
          id: "last_14_days",
          name: "Last 14 days",
        },
        {
          id: "last_30_days",
          name: "Last 30 days",
        },
        // this.custom
        //   ? {
        //       id: "last_14_days",
        //       name: "Last 14 days",
        //     }
        //   : null,
        // this.custom
        //   ? {
        //       id: "last_30_days",
        //       name: "Last 30 days",
        //     }
        //   : null,
        this.custom
          ? {
              id: "custom",
              name: "Custom",
            }
          : null,
      ],
    };
  },
  computed: {
    formatedDateFrom() {
      return this.dateFrom && moment(this.dateFrom).format("DD-MMM-YYYY");
    },
    formatedDateTo() {
      return this.dateTo && moment(this.dateTo).format("DD-MMM-YYYY");
    },
  },
  async mounted() {
    if (this.defaultSelected) {
      this.selectedDate = this.$route.query.dateType || "today";
      await this.onDateChange(this.selectedDate);
    } else {
      this.selectedDate = this.$route.query.dateType || this.dateType;
      await this.onDateChange(this.selectedDate);
    }
  },
  methods: {
    currentDate() {
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;

      day = day < 10 ? "0" + day : day;
      month = month < 10 ? `0${month}` : month;
      let year = date.getFullYear();
      // This arrangement can be altered based on how we want the date's format to appear.
      let cDate = `${year}-${month}-${day}`;
      return cDate;
    },
    validationOneMonth() {
      if (this.validateDateLimit) {
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();
        day = day < 10 ? "0" + day : day;

        if (month == 0) {
          month = 12;
          year = year - 1;
        }
        month = month < 10 ? `0${month}` : month;
        // This arrangement can be altered based on how we want the date's format to appear.
        let cDate = `${year}-${month}-${day}`;
        return cDate;
      } else return;
    },
    // setOrderinDateArray(dt) {
    //   let arr = [...dt];
    //   if (arr.length > 1) {
    //     if (arr[0] > arr[1]) {
    //       this.date[0] = arr[1];
    //       this.date[1] = arr[0];
    //     }
    //   }
    // },

    onDateChange(value) {
      this.$emit("key", value);
      if (value == "custom") {
        this.isCheckRange();
      } else {
        this.dateFrom = null;
        this.dateTo = null;

        const filterDate = this.customizedDate(value);
        this.$emit("input", filterDate);
        this.$emit("change", filterDate);
      }
    },
    isCheckRange() {
      if (this.dateFrom && this.dateTo) {
        this.$emit("input", [this.dateFrom, this.dateTo]);
        this.$emit("change", [this.dateFrom, this.dateTo]);
      }
    },
    customizedDate(filter) {
      const today = new Date();
      const currentDate = new Date(today);
      const tdate = this.formatDate(currentDate);
      if (filter === "today") {
        return [tdate, tdate];
      } else if (filter === "yesterday") {
        currentDate.setDate(today.getDate() - 1);
        const ydate = this.formatDate(currentDate);
        return [ydate, ydate];
      } else if (filter === "last_3_days") {
        currentDate.setDate(today.getDate() - 2); // Go back 2 more days for a total of 3 days
        return [this.formatDate(currentDate), tdate];
      } else if (filter === "last_7_days") {
        currentDate.setDate(today.getDate() - 6); // Go back 6 more days for a total of 7 days
        return [this.formatDate(currentDate), tdate];
      } else if (filter === "last_14_days") {
        currentDate.setDate(today.getDate() - 13); // Go back 13 more days for a total of 14 days
        return [this.formatDate(currentDate), tdate];
      } else if (filter === "last_30_days") {
        currentDate.setDate(today.getDate() - 29); // Go back 29 more days for a total of 30 days
        return [this.formatDate(currentDate), tdate];
      } else {
        return null; // Invalid filter
      }
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      // return `${year}-${month}-${day}`;
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style lang="sass" scoped>
.DateDropDown
  display: flex
  gap: 1rem
  flex-wrap: wrap
</style>
